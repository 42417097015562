import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Figure.module.scss'
import Img from 'gatsby-image'

const b = bem.figure(css)

const Figure = props => {
	const { caption, title, isLink, isMargin, ...rest } = props
	const originalImg = props.fluid.originalImg
	const { maxWidth } = props.fluid
	return (
		<figure className={b({ 'is-caption': !!caption, 'is-margin': isMargin })} style={{ maxWidth }}>
			{isLink && originalImg && (
				<a className={b('link')} href={originalImg} title={title}>
					<Img {...rest} />
				</a>
			)}
			{!isLink && <Img {...rest} />}
			{caption && <figcaption className={b('caption')}>{caption}</figcaption>}
		</figure>
	)
}

Figure.propTypes = {
	caption: PropTypes.string, // подпись к изображению
	title: PropTypes.string, // аттрибут title для ссылки
	alt: PropTypes.string, // alt изображения
	isMargin: PropTypes.bool, // Отступ сверху и снизу
	isLink: PropTypes.bool, // Сделать ссылкой на изображение
	fluid: PropTypes.object.isRequired,
	placeholderStyle: PropTypes.object,
}

export default Figure
