import React, { Fragment, useState } from 'react'
import css from './Calc.module.scss'
import bem from 'bem'
import { Col, Grid, Row } from 'react-flexbox-grid'
import Sector from 'components/Sector'
import Increment from 'components/Increment'
import Ruble from 'components/Ruble'
import Button from 'components/Button'
import Secondary from 'components/Secondary'
import { Field, Form } from 'react-final-form'
import HelperButton from 'components/HelperButton'
import AdapterSlider from 'components/Adapter/Slider'
import useSiteMetadata from 'hooks/useSiteMetadata'

const b = bem.scenePartnerCalc(css)

const FieldSlider = props => {
	const [isHelp, setIsHelp] = useState(false)
	const { formProps, title, help, min, max, name } = props
	const { form, values } = formProps
	return (
		<Fragment>
			<div className="mb-xs">
				<p className="fs-h4 no-last">
					{title} — {values[name]}
					<HelperButton
						className={b('slider-helper-icon')}
						onClick={() => setIsHelp(!isHelp)}
						active={isHelp}
						negative
					/>
				</p>
				{isHelp && (
					<Secondary className="mb" negative>
						{help}
					</Secondary>
				)}
			</div>
			<Field component={AdapterSlider} name={name} min={min} max={max} form={form} values={values} noMargin negative />
		</Fragment>
	)
}

const Calc = () => {
	const [total, setTotal] = useState(0)
	const { partnerUrl } = useSiteMetadata()
	const registerUrl = partnerUrl + '/sign-up'

	const handleChangeTotal = ({ db, parsing }) => {
		setTotal(db * 700 + parsing * 2500)
	}

	return (
		<div className={b()}>
			<Sector color="blue" isGradient>
				<Grid fluid>
					<Row>
						<Col md={6} className="mb-xl lg_mb-0">
							<h2 className="mb">Сколько можно заработать?</h2>
							<p className="fs-h4 mb-lg">
								За&nbsp;продажи баз компаний&nbsp;&mdash; <strong>25%</strong>
								<br />
								За&nbsp;парсинг&nbsp;&mdash; <strong>30%</strong>
							</p>
							<Form
								onSubmit={() => {}}
								initialValues={{ db: 25, parsing: 2 }}
								render={formProps => {
									const { handleSubmit, values } = formProps
									handleChangeTotal(values)
									return (
										<form onSubmit={handleSubmit}>
											<div className="mb-lg">
												<FieldSlider
													formProps={formProps}
													title="Продано баз компаний"
													help={
														<Fragment>
															При стоимости базы в 2800
															<Ruble />. Базы обогащаются и будут стоить значительно дороже
														</Fragment>
													}
													min={0}
													max={100}
													name="db"
												/>
											</div>
											<div>
												<FieldSlider
													formProps={formProps}
													title="Заказов парсинга сайтов"
													help={
														<Fragment>
															При стоимости парсинга в 10 000
															<Ruble />. Крупные клиенты готовы платить за парсинг более 60 000
															<Ruble />
														</Fragment>
													}
													min={0}
													max={30}
													name="parsing"
												/>
											</div>
										</form>
									)
								}}
							/>
						</Col>
						<Col mdOffset={1} md={5}>
							<h2>Ваша прибыль</h2>
							<Secondary negative className="mb-lg">
								Рассчитана на основе статистических данных
							</Secondary>
							<div className={b('total', 'mb-lg')}>
								~<Increment isFormat>{total}</Increment> <Ruble />
							</div>
							<Button isLink to={registerUrl} title="Партнерская программа" size="lg" theme="light">
								Стать партнером
							</Button>
						</Col>
					</Row>
				</Grid>
			</Sector>
		</div>
	)
}

export default Calc
