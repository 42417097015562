import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Explanations.module.scss'
import { Col, Grid, Row } from 'react-flexbox-grid'

const b = bem.explanations(css)

const Explanations = props => {
	const { titleTag: TitleTag, items } = props
	return (
		<Grid fluid className={b({})}>
			<Row>
				{items.map(({ title, text }, index) => {
					return (
						<Col key={`explanation-${index}`} md={6}>
							<div className={b('item')}>
								<TitleTag className={b('title')}>{title}</TitleTag>
								<div className={b('text')}>{text}</div>
							</div>
						</Col>
					)
				})}
			</Row>
		</Grid>
	)
}

Explanations.defaultProps = {
	titleTag: 'h3',
}

Explanations.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			text: PropTypes.object.isRequired,
		})
	),
	titleTag: PropTypes.string,
}

export default Explanations
