import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Cube.module.scss'

const b = bem.cube(css)

const Cube = props => {
	const { children, color } = props
	return (
		<div className={b({ color })}>
			<div className={b('content')}>{children}</div>
		</div>
	)
}

Cube.propTypes = {
	color: PropTypes.oneOf(['white']),
}

export default Cube
