import React from 'react'
import css from './Profit.module.scss'
import bem from 'bem'
import Icon from 'components/Icon'
import { numberFormat } from 'utils/numberFormat'
import Ruble from 'components/Ruble'
import Content from 'components/Content'

const b = bem.scenePartnerProfit(css)

const Profit = () => {
	return (
		<div className={b()}>
			<div className={b('inner')}>
				<div className={b('angles')}>
					<Icon className="angles" type="angles" />
					<div className={b('circle', { c: 1 })} />
					<div className={b('circle', { c: 2 })} />
					<div className={b('circle', { c: 3 })} />
					<div className={b('circle', { c: 4 })} />
					<div className={b('circle', { c: 5 })} />
					<div className={b('circle', { c: 6 })} />
					<div className={b('item', { i: 1 })}>
						<div className={b('item-price')}>
							~{numberFormat(700)}
							<Ruble />
						</div>
						<div className={b('item-desc')}>
							Вы получите за
							<br />
							<strong>продажу базы компаний</strong>
						</div>
					</div>
					<div className={b('item', { i: 2 })}>
						<div className={b('item-price')}>
							~{numberFormat(2500)}
							<Ruble />
						</div>
						<div className={b('item-desc')}>
							Вы получите за
							<br />
							<strong>заказ парсинга сайта</strong>
						</div>
					</div>
				</div>
				<Content className="mb-xl">
					<blockquote className="blockquote blockquote__negative">
						<p className="fs-h3">Вы&nbsp;сможете получать прибыль, просто рассказывая о&nbsp;нас в&nbsp;интернете</p>
						<p>
							Например, если клиенты, которых вы&nbsp;приведете будут покупать <strong>20&nbsp;баз</strong>{' '}
							и&nbsp;заказывать <strong>3&nbsp;парсинга</strong> ежемесячно, то&nbsp;в&nbsp;год вы&nbsp;заработаете{' '}
							<strong>258&nbsp;000 рублей</strong>
						</p>
					</blockquote>
				</Content>
				<Icon type="triangleLB" className="block" />
			</div>
		</div>
	)
}

export default Profit
