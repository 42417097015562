import React from 'react'
import css from './How.module.scss'
import bem from 'bem'
import { Col, Grid, Row } from 'react-flexbox-grid'
import Img from 'gatsby-image'
import Sector from 'components/Sector'

const b = bem.scenePartnerHow(css)

const How = ({ pictures }) => {
	return (
		<div className={b()}>
			<Sector title="Как это работает" titleCentered>
				<Grid fluid className="mb-lg md_mb-xl fs-h4">
					<Row>
						<Col lgOffset={2} lg={8}>
							<ol>
								<li>Вы размещаете партнерские ссылки в интернете</li>
								<li>Мы получаем клиентов и выполняем услуги</li>
								<li>Вы получаете процент от прибыли</li>
							</ol>
						</Col>
					</Row>
				</Grid>
				<div className={b('how-img')}>
					<Img
						fluid={pictures['how']}
						alt="Как работает партнерская программа"
						placeholderStyle={{ visibility: 'hidden' }}
					/>
				</div>
			</Sector>
		</div>
	)
}

export default How
