import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const WithPartner = Component => {
	const Enhanced = props => {
		const images = useStaticQuery(graphql`
			query {
				allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "partner" } }) {
					edges {
						node {
							name
							childImageSharp {
								fluid(maxWidth: 930) {
									...GatsbyImageSharpFluid
									originalImg

									# Если изоражение меньше ширины контейнера, оно не будет растягиваться больше своего размера
									...GatsbyImageSharpFluidLimitPresentationSize
								}
							}
						}
					}
				}
			}
		`)
		return <Component {...props} images={images} />
	}

	return Enhanced
}

export default WithPartner
